import React, { useState, useRef } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import "../styles.css";

const CustomAccordion = ({ heading, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const accordionRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen((prev) => {
      const newState = !prev;

      if (newState) {
        setTimeout(() => {
          accordionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100); // Small delay to ensure rendering completes
      }

      return newState;
    });
  };

  return (
    <div className="custom-accordion" ref={accordionRef}>
      <div className="accordion-item">
        <div
          className="accordion-header"
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-controls="accordion-body"
        >
          <p className="accordion-heading">
            {heading}
            <span className="accordion-icon">
              {isOpen ? <BsChevronUp /> : <BsChevronDown />}
            </span>
          </p>
        </div>
        {isOpen && (
          <div id="accordion-body" className="accordion-body">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomAccordion;