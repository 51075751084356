import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <Container fluid className="bg-light py-3 mt-5">
      <Row className="d-flex justify-content-between align-items-center">
        {/* Left Button: Reset All Details */}
        <Col sm="4" className="text-left">
          <Button variant="secondary" size="sm" style={{ width: "150px" }}>
            Reset All Details
          </Button>
        </Col>

        {/* Right Buttons: Save Quote & Get Rates */}
        <Col sm="4" className="d-flex justify-content-end">
          <Button variant="primary" size="sm" className="me-2" style={{ width: "100px" }}>
            Save Quote
          </Button>
          <Button variant="success" size="sm" style={{ width: "100px" }}>
            Get Rates
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;