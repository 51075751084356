import React from 'react';
import CheckboxWithTooltip from './Checkbox';
import { Col, Row } from 'react-bootstrap';

const CheckboxGroup = ({ options, onChange }) => (
    <Row className="mb-3">
      {options.map((option, index) => (
        <Col key={index} md={6} className="mb-2">
          <CheckboxWithTooltip
            label={option.label}
            tooltipText={option.tooltipText}
            checked={false}
            onChange={onChange}
          />
        </Col>
      ))}
    </Row>
  );

export default CheckboxGroup;