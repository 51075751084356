import React, { useContext, useReducer } from 'react';

import { reducer, initialState } from './reducer';

import { KEYPAIR } from '@/types/interfaces';

interface Props {
    children: JSX.Element | JSX.Element[];
}

interface CONTEXTVALUE {
    state: any;
    dispatch: React.Dispatch<{ type: string; data: KEYPAIR }>;
}
const AppContext = React.createContext<CONTEXTVALUE | null>(null);
export const ContainerContextProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value: CONTEXTVALUE = {
        state,
        dispatch,
    };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useContainerContext() {
    const context = useContext(AppContext);
    if (context === null) {
        throw new Error('useApp must be used within an AppProvider');
    }
    return context;
}
