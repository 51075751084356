import { useReducer, useMemo } from 'react';
// import { FormattedMessage } from 'react-intl';
import { useCookies } from 'react-cookie';

import { reducer, initialState } from './reducer';

import messages_de from '@/locale/sp.json';
import messages_en from '@/locale/en.json';

export const messages = {
    sp: messages_de,
    en: messages_en,
};
// export const language = navigator.language.split(/[-_]/)[0];

interface Trans {
    (message: string): string;
}
interface DefaultLanguage {
    (): string;
}
declare global {
    interface Window {
        lang: any;
    }
}
window.lang = {};
export const useTranslate = () => {
    //   const intl = useIntl();
    const [cookies] = useCookies(['lang']);
    const [state, dispatch] = useReducer(reducer, initialState);

    const { trans, language, defaultLanguage } = useMemo(() => {
        const language = cookies.lang || state.lang;

        const defaultLanguage: DefaultLanguage = () => {
            const languages = {
                sp: 'SPANISH',
                en: 'ENGLISH',
            };
            if (language) {
                return languages[language];
            } else return 'ENGLISH';
        };

        const trans: Trans = message => {
            try {
                window.lang[message] = message;
                return messages[language][message] || message;
            } catch (err) {
                return message;
            }
            // return <FormattedMessage id={message} defaultMessage={message} description={message} />;
        };

        return {
            trans,
            language,
            defaultLanguage,
        };
    }, [cookies.lang, state.lang]);

    return {
        trans,
        defaultLanguage,
        language,
        dispatchTranslate: dispatch,
    };
};
