import React from 'react';
import { Form } from 'react-bootstrap';
import { State } from 'country-state-city';
interface PROPS {
    countryCode: string;
}

const StateSelect = ({ countryCode }: PROPS) => {
    const states = countryCode ? State.getStatesOfCountry(countryCode) : [];

    return (
        <Form.Group controlId="stateSelect">
            <Form.Label style={{ color: 'black' }}>Province/State</Form.Label>
            <Form.Control as="select">
                <option value="">Select a state</option>
                {states.map(state => (
                    <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default StateSelect;
