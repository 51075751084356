interface InitalState {
    [key: string]: any;
}

export const loadDetail = {
    pickup: {
        address: '',
        buildingNo: '',
        tag: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        name: '',
        phone: '',
        location: '',
    },
    dropoff: {
        address: '',
        buildingNo: '',
        tag: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        name: '',
        phone: '',
        location: '',
    },
    pickupDate: new Date().toISOString().split('.')[0],
    dropoffDate: '',
    size: '',
    weight: '',
    details: '',
    reference: '',
    quantity: '',
    rate: '',
    price: '',
    priority: '',
    is_bulk: false,
};
const initialState: InitalState = {
    showAddress: false,
    newLoadModal: false,
    exitModel: false,
    loads: [],
    loadDetail: loadDetail,
    index: null,
};
interface Action {
    type: string;
    data: InitalState;
}
function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, ...action.data };
        default:
            return state;
    }
}

export { reducer, initialState };
