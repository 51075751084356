import React from 'react';
import { Form, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { BsInfoCircle } from 'react-icons/bs';
import "../styles.css"

interface CheckboxProps {
    label: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    tooltipText?: string;
}

const CheckboxWithTooltip = ({ label, checked, onChange, tooltipText }: CheckboxProps) => {
    return (
        <Form.Group>
            <div style={{ display: 'flex', alignItems: 'center' }} >
                <Form.Check
                    type="checkbox"
                    id={`checkbox-${label}`}
                    checked={checked}
                    onChange={onChange}
                    style={{ marginRight: '10px' }}
                />
                <Form.Label htmlFor={`checkbox-${label}`} style={{ marginBottom: 0, marginRight: '10px', color: "#666" }}>
                    {label}
                </Form.Label>
                {tooltipText && (
                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${label}`}>{tooltipText}</Tooltip>}>
                        <span style={{ cursor: 'pointer' }}>
                            <BsInfoCircle size={16} />
                        </span>
                    </OverlayTrigger>
                )}
            </div>
        </Form.Group>
    );
};

export default CheckboxWithTooltip;