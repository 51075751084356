import React from 'react';
import { Form } from 'react-bootstrap';
import { Country } from 'country-state-city';

interface PROPS {
    onCountryChange: (field: string) => void;
}

const CountrySelect = ({ onCountryChange }: PROPS) => {
    const countries = Country.getAllCountries();

    return (
        <Form.Group controlId="countrySelect">
            <Form.Label style={{ color: 'black' }}>Country</Form.Label>
            <Form.Control as="select" onChange={e => onCountryChange(e.target.value)}>
                <option value="">Select a country</option>
                {countries.map(country => (
                    <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default CountrySelect;
