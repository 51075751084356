import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";

import UnitForm from "./Unit";
import PalletActions, { CourierPaksActions, PackageActions } from "./PalletActions";
import { TABS } from "../constants";

const UnitContainer: React.FC<UnitContainerProps> = ({ fields ,type}) => {
  const initialUnit = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [units, setUnits] = useState<Unit[]>([initialUnit]);

  const addUnit = () => {
    setUnits([...units, { ...initialUnit }]);
  };

  const updateUnit = (index: number, field: string, value: string) => {
    const newUnits = [...units];
    newUnits[index][field] = value;
    setUnits(newUnits);
  };

  const removeUnit = (index: number) => {
    if (units.length > 1) {
      setUnits(units.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = () => {
    console.log("Submitted Data:", units);
  };

  return (
    <div>
      {units.map((unit, index) => (
        <Card key={index} className="mb-3">
          <Card.Body>
            <UnitForm
              index={index}
              unit={unit}
              updateUnit={updateUnit}
              removeUnit={removeUnit}
              fields={fields}
            />
          </Card.Body>
        </Card>
      ))}

      <Row className="mt-3">
        <Col md={12} className="d-flex justify-content-end">
          {
            type === TABS.Pallet ? (
              <PalletActions />
            ) : type === TABS.Package ? (
                <PackageActions />
              ) : (
                <CourierPaksActions />
              )
          }
        </Col>
        <Col className="d-flex justify-content-end">
          <Button variant="primary" onClick={addUnit}>
            <BsPlus /> {
              type === TABS.Pallet ? "Add Pallet" : type === TABS.Package ? "Add Package" : type === TABS["Courier Pak"] ? "Add Courier Pak" : 'Add Envelope'
            }
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UnitContainer;
