import React, { useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsArrowLeftRight } from "react-icons/bs";

import CountrySelect from "./selectCountry";
import StateSelect from "./selectState";
import "../styles.css";
// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  street: Yup.string().required("Street Address is required"),
  city: Yup.string().required("City is required"),
  country: Yup.string().required("Country is required"),
  building: Yup.string().required("Building No is required"),
  state: Yup.string().required("State is required"),
  locationType: Yup.string().required("Location Type is required"),
  phone: Yup.string().required("Phone Number is required"),
  postalCode: Yup.string().required("Postal Code is required"),
});
interface Props {
  showResidentialAddressCheckbox?: boolean; // Optional, defaults to true
}

const ShippingForm = ({showResidentialAddressCheckbox= true}:Props) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      street: "",
      city: "",
      country: "",
      building: "",
      state: "",
      locationType: "",
      phone: "",
      postalCode: "",
      residentialAddress: false,
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Form Data:", values);
    },
  });

  const [selectedCountry, setSelectedCountry] = useState("");

  const locationsTypes = [
    {
      value: "commercial-business-with-dock",
      label: "Business - Tailgate Not Required",
    },
    {
      value: "commercial-business-with-tailgate-required",
      label: "Business - Tailgate Required",
    },
    {
      value: "residential-with-tailgate-required",
      label: "Residence - Tailgate Required",
    },
    {
      value: "residential-without-tailgate-required",
      label: "Residence - Tailgate Not Required",
    },
  ];

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className="p-4 border rounded bg-light"
    >
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3">
            <Form.Label style={{ color: "black" }}>Name *</Form.Label>
            <Form.Control
              name="name"
              {...formik.getFieldProps("name")}
              isInvalid={formik.touched.name && !!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label style={{ color: "black" }}>
              Postal / Zip Code *
            </Form.Label>
            <Form.Control
              name="postalCode"
              {...formik.getFieldProps("postalCode")}
              isInvalid={
                formik.touched.postalCode && !!formik.errors.postalCode
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.postalCode}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label style={{ color: "black" }}>Street Address *</Form.Label>
            <Form.Control
              name="street"
              {...formik.getFieldProps("street")}
              isInvalid={formik.touched.street && !!formik.errors.street}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.street}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label style={{ color: "black" }}>
                  Building No *
                </Form.Label>
                <Form.Control
                  name="building"
                  {...formik.getFieldProps("building")}
                  isInvalid={
                    formik.touched.building && !!formik.errors.building
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.building}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label style={{ color: "black" }}>City *</Form.Label>
                <Form.Control
                  name="city"
                  {...formik.getFieldProps("city")}
                  isInvalid={formik.touched.city && !!formik.errors.city}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.city}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <CountrySelect onCountryChange={setSelectedCountry} />
            </Col>
            <Col md={6}>
              <StateSelect countryCode={selectedCountry} />
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label style={{ color: "black" }}>Location Type *</Form.Label>
            <Form.Control
              as="select"
              name="locationType"
              {...formik.getFieldProps("locationType")}
              isInvalid={
                formik.touched.locationType && !!formik.errors.locationType
              }
            >
              <option value="" disabled>
                Select
              </option>
              {locationsTypes.map((location) => (
                <option key={location.value} value={location.value}>
                  {location.label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.locationType}
            </Form.Control.Feedback>
          </Form.Group>
          {
            showResidentialAddressCheckbox && (<Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Residential Address"
                    name="residentialAddress"
                    {...formik.getFieldProps("residentialAddress")}
                    isInvalid={
                      formik.touched.residentialAddress &&
                      !!formik.errors.residentialAddress
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.residentialAddress}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>)
          }
          
        </Col>
      </Row>
    </Form>
  );
};
interface ShippingHeaderProps {
  text: string;
  leftIcon: React.ReactNode;
  rightIcon: React.ReactNode;
}

const ShippingHeader: React.FC<ShippingHeaderProps> = ({
  text,
  leftIcon,
  rightIcon,
}) => {
  return (
    <div className="shipping-header">
      <div className="header-left">
        <span className="header-icon">{leftIcon}</span>
        <h2 className="header-title">{text}</h2>
      </div>
      <div className="header-right">
        <span className="header-icon">{rightIcon}</span>
        <span className="address-book">Address Book</span>
      </div>
    </div>
  );
};

export const ShippingFormContainer = ({showResidentialAddressCheckbox= true}:Props) => {
  return (
    <div className="shipping-container d-flex justify-content-center align-items-center">
      {/* Left Form */}
      <div className="shipping-form">
        <ShippingHeader
          text="Shipping From"
          leftIcon={<i className="fas fa-sign-out"></i>}
          rightIcon={<i className="fas fa-book"></i>}
        />

        <ShippingForm showResidentialAddressCheckbox={showResidentialAddressCheckbox}/>
      </div>

      {/* Middle Button */}
      <div className="switch-button mx-3">
        <Button variant="outline-secondary" className="rounded-circle p-3">
          <i className="fas fa-sync" style={{ fontSize: "24px" }}></i>
        </Button>
      </div>

      {/* Right Form */}
      <div className="shipping-form">
        <ShippingHeader
          text="Shipping To"
          leftIcon={<i className="fas fa-sign-in"></i>}
          rightIcon={<i className="fas fa-book"></i>}
        />
        <ShippingForm  showResidentialAddressCheckbox={showResidentialAddressCheckbox}/>
      </div>
    </div>
  );
};

export default ShippingForm;
