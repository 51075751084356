import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Col, OverlayTrigger, Row } from "react-bootstrap";
import { Tab, Tabs } from "react-bootstrap";
import {
  BsGrid,
  BsBoxSeam,
  BsTruck,
  BsEnvelope,
  BsToggles,
  BsArrowsAngleContract,
  BsChevronUp,
  BsChevronDown,
  BsInfoCircle,
} from "react-icons/bs";
import { Accordion, Card, Form } from "react-bootstrap";

import AddOrderForm from "./Components/AddOrderForm";
import { ContextProvider, useModuleContext } from "./context";
import { ShippingFormContainer } from "./Components/addQoutefrom";
import { FieldConfig } from "./types";
import dropdowns, { TABS } from "./constants";

import styles from "@/styles/Components/Addload/Addload.module.scss";
import Container from "@/pages/Container";
import Modal from "@/components/Default/Modal";
import AddAddressForm from "@/pages/Address/Components/AddressForm";
import { REQUEST } from "@/store/interfaces";
import { useLoading, useRequest } from "@/components/App";
import Tooltip from "@/components/Default/Tooltip";
import UnitContainer from "@/pages/Orders/AddSalesOrder/Components/UnitContainer";

import "./styles.css";
import Checkbox from "./Components/Checkbox";
import CustomAccordion from "./Components/Accordian";
import SelectComponent from "./Components/Select";
import InsuranceAccordion from "./Components/InsurenceAccordian";
import SignatureOptions from "./Components/SignatureOptions";
import CheckboxGroup from "./Components/CheckboxGroup";
import PalletActions from "./Components/PalletActions";
import Footer from "./Components/Footer";

function AddLoad() {
  const navigate = useNavigate();
  const { request, loading } = useRequest();
  const { SimpleLoader } = useLoading();
  const { id } = useParams();
  const { state, dispatch } = useModuleContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("CAD");
  const [insurance, setInsurance] = useState(false);
  const [selectedOption, setSelectedOption] = useState("no-signature");

  const toggleAccordion = () => setIsOpen(!isOpen);
  const getEquipmentList = useCallback(async () => {
    (await request("TRUCKS_GET_EQUIPMENT_LIST")) as REQUEST;
  }, []);

  const closeModal = (key: string) => {
    dispatch({ type: "SET_DATA", data: { ...state, [key]: false } });
  };

  const getLoadDetailByID = useCallback(async (id: string) => {
    const res = (await request("LOADS_GET_LOAD_DETAIL", { _id: id })) as any;
    if (res?.data) {
      dispatch({
        type: "SET_DATA",
        data: {
          ...state,
          ...res.data,
          isEditPickup: true,
          customerAddress: res?.data?.isSAPType ? res?.data?.dropoff : {},
          pickupAddress: res?.data?.isSAPType ? res?.data?.pickup : {},
        },
      });
    }
  }, []);
  const nextPreviousOrder = useCallback(async (id: string, type: string) => {
    const res = (await request("ORDERS_NEXT_PREVIOUS", {
      loadId: id,
      type: type,
    })) as any;
    if (res.status && res?.data?._id) {
      await getLoadDetailByID(res?.data?._id);
      navigate(`/loads/editload/${res?.data?._id}?prev=neworder`);
    }
  }, []);

  const [selectedInsurance, setSelectedInsurance] = useState("");

  const handleSelectChange = (e) => {
    setSelectedInsurance(e.target.value);
  };

  const insuranceOptions = [
    { value: "internal", label: "Catena" },
    { value: "carrier", label: "Carrier" },
  ];
  const [showRates, setShowRates] = useState(false);
  const [weightOption, setSelectedWeightOption] = useState("metric"); // Default to metric

  const goBackToOrders = () => {
    navigate("/sales-orders");
  };
  useEffect(() => {
    getEquipmentList();
    if (id) {
      getLoadDetailByID(id);
    }
  }, []);

  const fieldsSet1: FieldConfig[] = [
    { name: "length", label: "Length (in)" },
    { name: "width", label: "Width (in)" },
    { name: "height", label: "Height (in)" },
    { name: "weight", label: "Weight (lbs)" },
    { name: "description", label: "Description" },
    { name: "residentialAddress", label: "Residential Address", type: "check-box" },
  ];

  const fieldsSet2: FieldConfig[] = [
    { name: "quantity", label: "Quantity" },
    { name: "length", label: "Length (in)" },
    { name: "width", label: "Width (in)" },
    { name: "height", label: "Height (in)" },
    { name: "weight", label: "Weight (lbs)" },
    ...dropdowns,
    { name: "pieces", label: "Pieces" },
    { name: "description", label: "Description" },
  ];

  const fieldsSet3: FieldConfig[] = [
    { name: "weight", label: "Weight (lbs)" },
    { name: "description", label: "Description" },
  ];

  const checkboxOptions = [
    { label: "Dangerous Goods", tooltipText: "Learn More" },
    { label: "Stackable", tooltipText: "Learn More" },
    { label: "Limited Access", tooltipText: "Learn More" },
    { label: "Appointment Delivery", tooltipText: "Learn More" },
    { label: "Threshold Delivery", tooltipText: "Learn More" },
    { label: "Threshold Pickup", tooltipText: "Learn More" },
    { label: "In-Bond", tooltipText: "Learn More" },
    { label: "Protect from Freeze", tooltipText: "Learn More" },
    { label: "Trade Show Delivery", tooltipText: "Learn More" },
    { label: "Amazon/FBA Delivery", tooltipText: "Learn More" },
  ];

  const dummy = () => {
    console.log("dummy");
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  
  return (
    <Container title={id ? "Edit Load" : "Add Load"}>
      <Card
        className="p-3"
        style={{ width: "98%", marginLeft: "1%", marginRight: "1%" }}
      >
        <h2 className="heading" style={{ marginLeft: "1%" }}>
          Shipping Details
        </h2>
        <div className="line"></div>

        <p className="mb-3" style={{ margin: "1%", color: "#666" }}>
          Please add the details of what you are shipping.
        </p>
        <Tabs
          defaultActiveKey="pallet"
          id="shipping-tabs"
          className="mb-3 custom-tabs custom-tab-title"
          style={{ paddingBottom: "3%" }}
        >
          {/* Pallet Tab */}
          <Tab
            eventKey="pallet"
            title={
              <>
                <BsGrid className="me-2" style={{ color: "#666" }} />
                Pallet
              </>
            }
          >
            <p className="mb-3" style={{ margin: "1%", color: "#666" }}>
              Please provide the Postal or ZIP Code of where you are shipping
              from and shipping to in order to get a quote.
            </p>

            {/* Shipping Form */}
            <div className="mb-3" style={{ width: "98%" }}>
              {/* <Card.Body> */}
              <ShippingFormContainer  showResidentialAddressCheckbox={false} />
              {/* </Card.Body> */}
            </div>

            <div className="mb-3 mx-auto" style={{ width: "98%" }}>
              <div className="header-left">
                <i className="fas fa-box" style={{ fontSize: "24px" }}></i>
                <h2 className="mb-3 heading">Dimensions & Weight</h2>
              </div>

              <div className="line"></div>

              {/* <Card.Body className="text-center"> */}
              <div className="d-flex justify-content-between align-items-center">
                {/* Left section (40%) */}
                <div style={{ flex: "0 0 40%" }}>
                  <Form.Check
                    type="checkbox"
                    label="Show both pallet and package rates for this shipment"
                    checked={showRates}
                    onChange={(e) => setShowRates(e.target.checked)}
                  />
                </div>

                {/* Center section (20%) */}
                <div style={{ flex: "0 0 20%", textAlign: "center" }}>
                  <a href="#" onClick={handleOpenModal}>
                    Class & Density Calculator
                  </a>
                </div>

                {/* Right section (40%) */}
                <div style={{ flex: "0 0 40%" }}>
                  {/* <Row>
                    <Col> */}
                  <Form.Group>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Form.Check
                        inline
                        type="radio"
                        label="Metric (cm & kg)"
                        name="measurement"
                        value="metric"
                        checked={weightOption === "metric"}
                        onChange={() => setSelectedWeightOption("metric")}
                        style={{ whiteSpace: "nowrap" }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Imperial (in & lbs)"
                        name="measurement"
                        value="imperial"
                        checked={weightOption === "imperial"}
                        onChange={() => setSelectedWeightOption("imperial")}
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </div>
                  </Form.Group>
                  {/* </Col>
                  </Row> */}
                </div>
              </div>

              {/* </Card.Body> */}
            </div>

            {/* Unit Container */}
            <div className="mb-3" style={{ width: "97%", marginLeft: "1%" }}>
              {/* <Card.Body> */}
              <UnitContainer fields={fieldsSet2} type= {TABS.Pallet} />
              {/* </Card.Body> */}
            </div>

            {/* Additional Services */}
            <div className="mb-3 p-3" style={{ width: "98%" }}>
              <div className="line"></div>
              <CheckboxGroup
                options={checkboxOptions.slice(0, 2)}
                onChange={dummy}
              />
            </div>

            <div className="mb-3 p-3" style={{ width: "98%" }}>
              <h2 className="heading">Additional Services for Pallets</h2>
              <div className="line-half"></div>

              <CheckboxGroup
                options={checkboxOptions.slice(2, 6)}
                onChange={dummy}
              />
              <CheckboxGroup
                options={checkboxOptions.slice(6)}
                onChange={dummy}
              />
            </div>

            {/* Insurance Accordion */}
            <InsuranceAccordion
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              selectedInsurance={selectedInsurance}
              handleSelectChange={handleSelectChange}
              insuranceOptions={insuranceOptions}
            />
          </Tab>

          {/* Package Tab */}
          <Tab
            eventKey="package"
            title={
              <>
                <BsBoxSeam className="me-2" />
                Package
              </>
            }
          >
            <p className="mb-3" style={{ margin: "1%", color: "#666" }}>
              Please provide the Postal or ZIP Code of where you are shipping
              from and shipping to in order to get a quote.
            </p>

            {/* Shipping Form */}
            <div className="mb-3">
              {/* <Card.Body> */}
              <ShippingFormContainer />
              {/* </Card.Body> */}
            </div>

            <div className="mb-3 mx-auto" style={{ width: "98%" }}>
              <div className="header-left">
                <i className="fas fa-box" style={{ fontSize: "24px" }}></i>
                <h2 className="mb-3 heading">Dimensions & Weight</h2>
              </div>

              <div className="line"></div>

              {/* <Card.Body className="text-center"> */}
              <div className="d-flex justify-content-between align-items-center">
                {/* Left section (40%) */}
                <div style={{ flex: "0 0 60%" }}>
                  <Form.Check
                    type="checkbox"
                    label="Show both pallet and package rates for this shipment"
                    checked={showRates}
                    onChange={(e) => setShowRates(e.target.checked)}
                  />
                </div>
                {/* Right section (40%) */}
                <div style={{ flex: "0 0 40%" }}>
                  {/* <Row>
                    <Col> */}
                  <Form.Group>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Form.Check
                        inline
                        type="radio"
                        label="Metric (cm & kg)"
                        name="measurement"
                        value="metric"
                        checked={weightOption === "metric"}
                        onChange={() => setSelectedWeightOption("metric")}
                        style={{ whiteSpace: "nowrap" }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Imperial (in & lbs)"
                        name="measurement"
                        value="imperial"
                        checked={weightOption === "imperial"}
                        onChange={() => setSelectedWeightOption("imperial")}
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </div>
                  </Form.Group>
                  {/* </Col>
                  </Row> */}
                </div>
              </div>

              {/* </Card.Body> */}
            </div>

            {/* Unit Container */}
            <div className="mb-3" style={{ width: "97%", marginLeft: "1%" }}>
              {/* <Card.Body> */}
              <UnitContainer fields={fieldsSet1}  type= {TABS.Package} />
              {/* </Card.Body> */}
            </div>
            <div className="line"></div>

            {/* Signature Options */}
            <div className="mb-3">
              {/* <Card.Body> */}
              <SignatureOptions
                selectedOption={selectedOption}
                onChange={setSelectedOption}
              />
              {/* </Card.Body> */}
            </div>

            {/* Insurance Accordion */}
            <InsuranceAccordion
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              selectedInsurance={selectedInsurance}
              handleSelectChange={handleSelectChange}
              insuranceOptions={insuranceOptions}
            />
          </Tab>

          {/* Courier Tab */}
          <Tab
            eventKey="courier"
            title={
              <>
                <BsTruck className="me-2" />
                Courier Pak
              </>
            }
          >
            <p className="mb-3" style={{ margin: "1%", color: "#666" }}>
              Please provide the Postal or ZIP Code of where you are shipping
              from and shipping to in order to get a quote.
            </p>

            {/* Shipping Form */}
            <div className="mb-3">
              {/* <Card.Body> */}
              <ShippingFormContainer />
              {/* </Card.Body> */}
            </div>

            <div className="mb-3 mx-auto" style={{ width: "98%" }}>
              <div className="header-left">
                <i className="fas fa-box" style={{ fontSize: "24px" }}></i>
                <h2 className="mb-3 heading">Dimensions & Weight</h2>
              </div>

              <div className="line"></div>

              {/* <Card.Body className="text-center"> */}
              <div className="d-flex justify-content-between align-items-center">
                {/* Left section (40%) */}
                {/* <div style={{ flex: "0 0 60%" }}> */}
                <Form.Check
                  type="checkbox"
                  label="Show both pallet and package rates for this shipment"
                  checked={showRates}
                  onChange={(e) => setShowRates(e.target.checked)}
                />
                {/* </div> */}
              </div>

              {/* </Card.Body> */}
            </div>

            {/* Unit Container */}
            <div className="mb-3" style={{ width: "97%", marginLeft: "1%" }}>
              {/* <Card.Body> */}
              <UnitContainer fields={fieldsSet3} type= {TABS["Courier Pak"]} />
              {/* </Card.Body> */}
            </div>

            <div className="line"></div>

            {/* Signature Options */}
            <div
              className="mb-3"
              style={{ width: "97%", marginLeft: "1%", marginBottom: "10px" }}
            >
              {/* <Card.Body> */}
              <SignatureOptions
                selectedOption={selectedOption}
                onChange={setSelectedOption}
              />
              {/* </Card.Body> */}
            </div>

            {/* Insurance Accordion */}
            <InsuranceAccordion
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              selectedInsurance={selectedInsurance}
              handleSelectChange={handleSelectChange}
              insuranceOptions={insuranceOptions}
            />
          </Tab>

          {/* Envelope Tab */}
          <Tab
            eventKey="envelope"
            title={
              <>
                <BsEnvelope className="me-2" />
                Envelope
              </>
            }
          >
            <p className="mb-3" style={{ margin: "1%", color: "#666" }}>
              Please provide the Postal or ZIP Code of where you are shipping
              from and shipping to in order to get a quote.
            </p>

            {/* Shipping Form */}
            <div className="mb-3">
              {/* <Card.Body> */}
              <ShippingFormContainer/>
              {/* </Card.Body> */}
            </div>
            <div className="mb-3 mx-auto" style={{ width: "98%" }}>
              <div className="header-left">
                <i className="fas fa-box" style={{ fontSize: "24px" }}></i>
                <h2 className="mb-3 heading">Dimensions & Weight</h2>
              </div>

              <div className="line"></div>

              {/* <Card.Body className="text-center"> */}
              <div className="d-flex justify-content-between align-items-center">
                {/* Left section (40%) */}
                <div style={{ flex: "0 0 60%" }}>
                  <Form.Check
                    type="checkbox"
                    label="I confirm this envelope only contains documents"
                    checked={showRates}
                    onChange={(e) => setShowRates(e.target.checked)}
                  />
                </div>
              </div>

              {/* </Card.Body> */}
            </div>
            {/* Signature Options */}
            <div className="line"></div>

            <div
              className="mb-3"
              style={{ width: "97%", marginLeft: "1%", marginBottom: "10px" }}
            >
              {/* <Card.Body> */}
              <SignatureOptions
                selectedOption={selectedOption}
                onChange={setSelectedOption}
              />
              {/* </Card.Body> */}
            </div>

            {/* Insurance Accordion */}
            <InsuranceAccordion
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              selectedInsurance={selectedInsurance}
              handleSelectChange={handleSelectChange}
              insuranceOptions={insuranceOptions}
            />
          </Tab>
        </Tabs>

        <Footer/>


      </Card>

    </Container>
  );
}

function index() {
  return (
    <ContextProvider>
      <AddLoad />
    </ContextProvider>
  );
}
export default index;
