export const dropdowns = [
    {
        name: 'freightClass',
        label: 'Freight Class',
        type: 'select',
        options: [
            { label: 'Class 50', value: '50' },
            { label: 'Class 55', value: '55' },
            { label: 'Class 60', value: '60' },
            { label: 'Class 65', value: '65' },
            { label: 'Class 70', value: '70' },
            { label: 'Class 77', value: '77' },
            { label: 'Class 77.5', value: '77.5' },
            { label: 'Class 85', value: '85' },
            { label: 'Class 92.5', value: '92.5' },
            { label: 'Class 100', value: '100' },
            { label: 'Class 110', value: '110' },
            { label: 'Class 125', value: '125' },
            { label: 'Class 150', value: '150' },
            { label: 'Class 175', value: '175' },
            { label: 'Class 200', value: '200' },
            { label: 'Class 250', value: '250' },
            { label: 'Class 300', value: '300' },
            { label: 'Class 400', value: '400' },
            { label: 'Class 500', value: '500' },
        ],
    },
    {
        name: 'palletType',
        label: 'Type',
        type: 'select',
        options: [
            { label: 'Pallet', value: 'pallet' },
            { label: 'Drum', value: 'drum' },
            { label: 'Boxes', value: 'boxes' },
            { label: 'Rolls', value: 'rolls' },
            { label: 'Pipes/Tubes', value: 'pipes-tubes' },
            { label: 'Bales', value: 'bales' },
            { label: 'Bags', value: 'bags' },
            { label: 'Cylinder', value: 'cylinder' },
            { label: 'Pails', value: 'pails' },
            { label: 'Reels', value: 'reels' },
            { label: 'Crate', value: 'crate' },
            { label: 'Loose', value: 'loose' },
            { label: 'Pieces', value: 'pieces' },
        ],
    },
];

export default dropdowns;


export const TABS ={
    Pallet: "Pallet",
    Package: "Package",
    'Courier Pak': "Courier Pak",
    Envelope: "Envelope",
  }