import { lazy } from 'react';

import AddSalesOrder from '@/pages/Orders/AddSalesOrder';
const Dashboard = lazy(() => import('@/pages/Dashboard'));
const DashboardLaneHistory = lazy(() => import('@/pages/Dashboard/LanesHistory'));
const DashboardTabs = lazy(() => import('@/pages/Dashboard/DashboardTabs'));

const Orders = lazy(() => import('@/pages/Orders'));
const DelayedOrders = lazy(() => import('@/pages/DelayedOrders'));
const RiskDelayedOrders = lazy(() => import('@/pages/RiskDelayedOrders'));

const Chat = lazy(() => import('@/pages/Chat'));
// Loads
const Addload = lazy(() => import('@/pages/Loads/Addload'));
const Loads = lazy(() => import('@/pages/Loads'));
const NearByLoadTrucks = lazy(() => import('@/pages/Loads/Components/NearByLoadTrucks'));

// Trucks
const Trucks = lazy(() => import('@/pages/Trucks'));
const AddTruck = lazy(() => import('@/pages/Trucks/Components/AddTruck'));
const EditTruck = lazy(() => import('@/pages/Trucks/Components/EditTruck'));
// Carrier
const Carrier = lazy(() => import('@/pages/Carrier'));
const AddCarrier = lazy(() => import('@/pages/Carrier/Components/AddCarrier'));
const EditCarrier = lazy(() => import('@/pages/Carrier/Components/EditCarrier'));
// Search Truck
const SearchTruck = lazy(() => import('@/pages/SearchTruck'));
// Favourites
const Favourites = lazy(() => import('@/pages/Favourites'));
const Tracking = lazy(() => import('@/pages/Tracking'));
const PublicTracking = lazy(() => import('@/pages/Tracking/public'));
const ContactUs = lazy(() => import('@/pages/ContactUs'));
const Profile = lazy(() => import('@/pages/Profile'));
const SearchLoad = lazy(() => import('@/pages/SearchLoad'));
const CarrierDetail = lazy(() => import('@/pages/CarrierDetail'));
const QuoteList = lazy(() => import('@/pages/QuoteList'));
// Calendar
const Calendar = lazy(() => import('@/pages/Calendar'));
const ConfirmDateByUser = lazy(() => import('@/pages/Calendar/Components/ConfirmDateByUser'));
// Auth
const Login = lazy(() => import('@/pages/Auth/Login'));
const ForgotPassword = lazy(() => import('@/pages/Auth/Components/ForgotPassword'));
const ResetPassword = lazy(() => import('@/pages/Auth/Components/ResetPassword'));
const VerifyEmail = lazy(() => import('@/pages/Auth/Components/VerifyEmail'));
const Register = lazy(() => import('@/pages/Auth/Register'));
// Address
const Address = lazy(() => import('@/pages/Address'));
const Addaddress = lazy(() => import('@/pages/Address/Components/Addaddress'));
const EditAddress = lazy(() => import('@/pages/Address/Components/EditAddress'));
const AddWarehouse = lazy(() => import('@/pages/Address/Components/AddWarehouse'));
const EditWarehouse = lazy(() => import('@/pages/Address/Components/EditWarehouse'));
// Lanes
const AddLanes = lazy(() => import('@/pages/Address/Components/AddLanes'));
const EditLanes = lazy(() => import('@/pages/Address/Components/EditLanes'));

// Quotes
const Quotes = lazy(() => import('@/pages/Quotes'));
const SubmitQuote = lazy(() => import('@/pages/Quotes/Components/SubmitQuote')); // public page for carriers
const SenderQuoteView = lazy(() => import('@/pages/Quotes/Components/SenderQuoteView')); // If user is logged in then API

// Purchase Order
const PurchaseOrders = lazy(() => import('@/pages/PurchaseOrders'));
const AddPurchaseOrder = lazy(() => import('@/pages/PurchaseOrders/Components/AddPurchaseOrder'));
const TrackOrder = lazy(() => import('@/pages/PurchaseOrders/Components/TrackOrder'));
const EditPurchaseOrder = lazy(() => import('@/pages/PurchaseOrders/Components/EditPurchaseOrder'));

// Map Box Routes
const MapBox = lazy(() => import('@/pages/MapBox'));
// Micro View Routes
const MicroView = lazy(() => import('@/pages/MicroView'));

// Reports
const Reports = lazy(() => import('@/pages/Reports'));
// Not Found
const NotFound = lazy(() => import('@/components/Default/Notfound'));
// Delivery Schedule
const DeliverySchedule = lazy(() => import('@/pages/DeliverySchedule'));
// Bulk Loads Schedule
const BulkLoadsSchedule = lazy(() => import('@/pages/BulkLoadsSchedule'));
//Order Delivery
const OrderDelivery = lazy(() => import('@/pages/Orders/Components/OrderDelivery'));
const OrderPickup = lazy(() => import('@/pages/Orders/Components/OrderPickup'));
// Version
const Versions = lazy(() => import('@/pages/Versions'));

//Coming Soon`
const ComingSoon = lazy(() => import('@/pages/ComingSoon'));

export default [
    {
        label: 'Home Page',
        path: '/',
        auth: true,
        component: Login,
    },
    {
        label: 'Dashboard',
        path: '/dashboard',
        auth: true,
        component: Dashboard,
        children: [
            { label: 'Lane History', path: '/lanehistory/:id', auth: true, component: DashboardLaneHistory },
            { label: 'Calendar', path: '/calendar', auth: true, component: DashboardTabs },
            { label: 'Drivers Schedule', path: '/driverschedule', auth: true, component: DashboardTabs },
            { label: 'Accepted Orders List', path: '/currentorderslist', auth: true, component: DashboardTabs },
            { label: 'Pending Orders List', path: '/pendingorderslist', auth: true, component: DashboardTabs },
            { label: 'New Orders List', path: '/neworderslist', auth: true, component: DashboardTabs },
            { label: 'Quoted Orders', path: '/quotedorderslist', auth: true, component: DashboardTabs },
            { label: 'Active Orders', path: '/activeorderslist', auth: true, component: DashboardTabs },
            { label: 'Completed Orders', path: '/completedorderslist', auth: true, component: DashboardTabs },
            { label: 'Risk of Delay', path: '/riskofdelay', auth: true, component: DashboardTabs },
            { label: 'Delayed Orders', path: '/delayedorders', auth: true, component: DashboardTabs },
        ],
    },
    {
        label: 'PurchaseOrders',
        path: '/purchase-orders',
        auth: true,
        component: PurchaseOrders,
        children: [
            { label: 'Add Purchase Order', path: '/add', auth: true, component: AddPurchaseOrder },
            { label: 'Track Purchase Order', path: '/track', auth: true, component: TrackOrder },
            { label: 'Edit Purchase Order', path: '/edit/:id', auth: true, component: EditPurchaseOrder },
        ],
    },
    {
        label: 'Address',
        path: '/address',
        auth: true,
        component: Address,
        children: [
            { label: 'Add Address', path: '/add', auth: true, component: Addaddress },
            { label: 'Add Lanes', path: '/add-lanes', auth: true, component: AddLanes },
            { label: 'Edit Lanes', path: '/edit-lanes/:id', auth: true, component: EditLanes },
            { label: 'Edit Address', path: '/edit/:id', auth: true, component: EditAddress },
            { label: 'Add Warehouse', path: '/add-warehouse', auth: true, component: AddWarehouse },
            { label: 'Edit Warehouse', path: '/edit-warehouse/:id', auth: true, component: EditWarehouse },
        ],
    },
    {
        label: 'Orders',
        path: '/sales-orders',
        auth: true,
        component: Orders,
        children: [{ label: 'Add Sales Order', path: '/add', auth: true, component: AddSalesOrder }],
    },
    {
        label: 'Delayed Orders',
        path: '/orders/delayed',
        auth: true,
        component: DelayedOrders,
    },
    {
        label: 'Risk Delayed Orders',
        path: '/orders/risk-delayed',
        auth: true,
        component: RiskDelayedOrders,
    },
    {
        label: 'Loads',
        path: '/loads',
        auth: true,
        component: Loads,
        children: [
            { label: 'Add Load', path: '/addload', auth: true, component: Addload },
            { label: 'Edit Load', path: '/editload/:id', auth: true, component: Addload },
            { label: 'Near Load Trucks', path: '/near-truck/:id', auth: true, component: NearByLoadTrucks },
        ],
    },
    {
        label: 'Chat',
        path: '/chat',
        auth: true,
        component: Chat,
    },
    {
        label: 'Trucks',
        path: '/trucks',
        auth: true,
        component: Trucks,
        children: [{ label: 'Add Truck', path: '/add', auth: true, component: AddTruck }],
    },
    {
        label: 'Carrier',
        path: '/carrier',
        auth: true,
        component: Carrier,
        children: [{ label: 'Add Carrier', path: '/add', auth: true, component: AddCarrier }],
    },
    {
        label: 'Search Trucks',
        path: '/search-trucks',
        auth: true,
        component: SearchTruck,
    },
    {
        label: 'Edit Truck',
        path: '/edit-truck/:truckID',
        auth: true,
        component: EditTruck,
    },
    {
        label: 'Edit Carrier',
        path: '/edit-carrier/:truckID',
        auth: true,
        component: EditCarrier,
    },
    {
        label: 'Favourites',
        path: '/favourites',
        auth: true,
        component: Favourites,
    },
    {
        label: 'Tracking',
        path: '/orders/tracking/:loadId',
        auth: false,
        component: Tracking,
    },
    {
        label: 'Tracking',
        path: '/tracking/share/:loadIdEncode',
        auth: false,
        component: PublicTracking,
    },
    {
        label: 'ContactUs',
        path: '/contactus',
        auth: true,
        component: ContactUs,
    },
    {
        label: 'Profile',
        path: '/profile',
        auth: true,
        component: Profile,
    },
    {
        label: 'SearchLoad',
        path: '/searchload',
        auth: true,
        component: SearchLoad,
    },
    {
        label: 'CarrierDetail',
        path: '/carrierdetail',
        auth: true,
        component: CarrierDetail,
    },
    {
        label: 'Quotes',
        path: '/quotes',
        auth: true,
        component: Quotes,
        children: [{ label: 'Quote View', path: '/viewQuote', auth: true, component: SenderQuoteView }],
    },

    {
        label: 'Submit Quote',
        path: '/confirmQuote',
        auth: false,
        component: SubmitQuote,
    },
    {
        label: 'QuoteList',
        path: '/quotelist',
        auth: true,
        component: QuoteList,
    },
    {
        label: 'Reports',
        path: '/reports',
        auth: true,
        component: Reports,
    },
    {
        label: 'Calendar',
        path: '/calendar',
        auth: true,
        component: Calendar,
        children: [
            {
                label: 'Calendar Date Update Confirmation',
                path: '/confirm-order-date-update/:id',
                auth: true,
                component: ConfirmDateByUser,
            },
        ],
    },
    {
        label: 'Login',
        path: '/login',
        auth: false,
        component: Login,
    },
    {
        label: 'Login',
        path: '/login/:email/:password',
        auth: false,
        component: Login,
    },
    {
        label: 'Forgot Password',
        path: '/forgot-password',
        auth: false,
        component: ForgotPassword,
    },
    {
        label: 'Reset Password',
        path: '/reset-password',
        auth: false,
        component: ResetPassword,
    },
    {
        label: 'Verify Email',
        path: '/verify-email',
        auth: false,
        component: VerifyEmail,
    },
    {
        label: 'Register',
        path: '/register',
        auth: false,
        component: Register,
    },
    {
        label: 'Not Found',
        path: '*',
        auth: false,
        component: NotFound,
    },
    {
        label: 'Delivery Schedule',
        path: '/delivery-schedule',
        auth: true,
        component: DeliverySchedule,
    },
    {
        label: 'Order Delivery',
        path: '/orderDelivery',
        auth: false,
        component: OrderDelivery,
    },
    {
        label: 'Order Pickup',
        path: '/orderPickup',
        auth: false,
        component: OrderPickup,
    },
    {
        label: 'Map Box',
        path: '/mapbox',
        auth: true,
        component: MapBox,
    },
    {
        label: 'Micro View',
        path: '/micro-view',
        auth: true,
        component: MicroView,
    },
    {
        label: 'Versions',
        path: '/versions',
        auth: true,
        component: Versions,
    },
    {
        label: 'Coming soon',
        path: '/comingSoon',
        auth: false,
        component: ComingSoon,
    },
    {
        label: 'Bulk Loads Schedule',
        path: '/bulk-loads-schedule',
        auth: true,
        component: BulkLoadsSchedule,
    },
];
