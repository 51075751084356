import { Form, Button, Row, Col } from "react-bootstrap";
import React from "react";
import "../styles.css";

const UnitForm: React.FC<UnitFormProps> = ({
  index,
  unit,
  updateUnit,
  removeUnit,
  fields,
}) => {
  return (
    <Row className="p-3">
      {fields.map((field) => (
        <Col md={3} key={field.name} className="mb-3">
          <Form.Group>
            {field.type !== "check-box" && (
              <Form.Label className="text" style={{ color: "black" }}>
                {field.label}
              </Form.Label>
            )}
            {field.type === "select" && field.options ? (
              <Form.Select
                value={unit[field.name] || ""}
                onChange={(e) => updateUnit(index, field.name, e.target.value)}
                className="form-control text"
              >
                <option value="">Select {field.label}</option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            ) : field.type === "number" ? (
              <Form.Control
                type="number"
                value={unit[field.name] || ""}
                onChange={(e) => updateUnit(index, field.name, e.target.value)}
              />
            ) : field.type === "check-box" ? (
              <Form.Check
                type="checkbox"
                label={field.label}
                checked={true}
                onChange={(e) => {
                  console.log("");
                }}
              />
            ) : (
              <Form.Control
                type="text"
                value={unit[field.name] || ""}
                onChange={(e) => updateUnit(index, field.name, e.target.value)}
              />
            )}
          </Form.Group>
        </Col>
      ))}
      {/* <Col md={12} className="d-flex justify-content-end">
        <Button
          variant="danger"
          onClick={() => removeUnit(index)}
        //   disabled={units.length <= 1}
        >
          Remove
        </Button>
      </Col> */}
    </Row>
  );
};

export default UnitForm;
