import React, { useReducer, useContext } from 'react';

import { reducer, initialState } from './reducer';

const AppContext = React.createContext(initialState);

interface Props {
    children: JSX.Element | JSX.Element[];
}
export const ContextProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <AppContext.Provider value={{ state, dispatch } as any}>{children}</AppContext.Provider>;
};

export function useModuleContext() {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useApp must be used within an ContextProvider');
    }
    return context;
}
