interface InitalState {
    [key: string]: any;
}
const initialState: InitalState = {};
interface Action {
    type: string;
    data: InitalState;
}
function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, ...action.data };
        default:
            return state;
    }
}

export { reducer, initialState };
